import * as React from 'react';
//import PropTypes from 'prop-types'
import { Link } from 'gatsby';
//import { StaticImage } from "gatsby-plugin-image";

import * as style from './learnmore.module.scss';

const LearnMore = () => (
  <section className={style.learnmore}>
    <div>
      <p>
        Find inspiration as the Lord guides you in ministry and get equipped for
        your calling to reach those around you.
      </p>
      <p>
      Through the Billy Graham  Archive and Research Center, you can study a variety of 
      original records demonstrating time-tested principles
        used by Billy Graham and his team to share the Gospel of Jesus Christ. Explore an 
        assortment of original materials and media in the Research Room—including papers,  photographs, videos, 
        and audio recordings—located in Billy Graham’s hometown of Charlotte, North Carolina.
      </p>
      <p>
        Browse our online collections today and request an appointment to visit 
        us in person for a hands-on learning experience.
      </p>
      <Link to="about">
        <div className="arrow_group">
          <h3>Learn More</h3>
          <p className="arrow_box"></p>
        </div>
      </Link>
    </div>
  </section>
);

export default LearnMore;
