import * as React from 'react';
//import PropTypes from 'prop-types'
//import { Link } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image";
import { StaticImage } from 'gatsby-plugin-image';

import * as style from './booktour.module.scss';

const BookTour = () => (
  <section className={style.booktour}>
    <div className={style.photos}>
      <StaticImage
        src="../images/upperleft.jpg"
        alt=""
        className={style.upperleft}
      />
      <StaticImage
        src="../images/lowerright.jpg"
        alt=""
        className={style.lowerright}
      />
    </div>
    <div className={style.booktourtext}>
      <h2>Explore the Billy Graham Library</h2>
      <p>
        Just a short distance away, you can also experience the Billy Graham
        Library. Take the Library’s free Journey of Faith tour, a series of
        videos, galleries and exhibits documenting Billy Graham’s legacy—and
        showing what can happen with an ordinary life surrendered to God.
      </p>
      <a href="https://billygrahamlibrary.org/">
        <div className="arrow_group">
          <h3>Book a Tour</h3>
          <p className="arrow_box"></p>
        </div>
      </a>
    </div>
  </section>
);

export default BookTour;
