import React from 'react';
import { useQuery } from '../hooks/useQuery';
//import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Accordion } from '@bgea/gatsby-components-common';

function FAQS() {
  const data = useQuery();
  const questions = data.faqs.nodes;

  return (
    <>
      {questions.map((faq, index) => (
        <React.Fragment key={index}>
          <Accordion
            title={faq.frontmatter.question}
            content={faq.frontmatter.answer}
          />
        </React.Fragment>
      ))}
    </>
  );
}

export default FAQS;
