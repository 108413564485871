import React, { useEffect, useState } from 'react';
import findingAid from '../constants/findingAid.json';

import * as style from './findingaid.module.scss';

function FindingAid() {
  const [searchText, setSearchText] = useState('');
  const [aidList, setAidList] = useState([...findingAid]);

  const changeFilter = (event) => {
    return setSearchText(event.target.value);
  };

  useEffect(() => {
    if (searchText) {
      const text = searchText?.toLowerCase();
      const newAidList = findingAid.filter(
        (aid) =>
          aid?.description?.toLowerCase().includes(text) ||
          aid?.title?.toLowerCase().includes(text),
      );
      setAidList(newAidList);
    } else {
      setAidList([...findingAid]);
    }
  }, [searchText]);

  return (
    <div id="finding-aid-wrapper">
      <div className='columns'>
        <p className='title-header column is-one-third' >Collections</p>
        <div className='column'>
          <input type="search" placeholder='Search Paper Collections by Keyword' onChange={changeFilter} style={{
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '1px solid #707070',
            borderRadius: '4px',
            width:'100%',
            maxWidth: '745px',
            height: '48px',
            padding: '1rem',
          }}></input>
        </div>
      </div>
      {searchText?.length > 0 && (
        <div className='mt-4 mb-4'> Your search results for "{searchText}"...</div>
      )}
      <table>
        <thead>
          <td colspan='1' style={{font: 'normal normal bold 18px/21px proxima-nova', textTransform: 'uppercase', borderColor: '#707070',}}>CN</td>
          <td colspan='2' style={{font: 'normal normal bold 18px/21px proxima-nova', textTransform: 'uppercase', borderColor: '#707070',}}>Title</td>
          <td colspan='4' style={{font: 'normal normal bold 18px/21px proxima-nova', textTransform: 'uppercase', borderColor: '#707070',}}>Description</td>
        </thead>
        <tbody>
          {aidList.map((cnaid) => (
            <tr key={cnaid.id}>
              <td colspan='1'>
                <span
                  style={{
                    color: '#000000',
                    border: '2px solid #000000',
                    borderRadius: '2em',
                    padding: '0',
                    height: '2rem',
                    width: '2rem',
                    display: 'inline-block',
                    textAlign: 'center',
                    lineHeight: '2',
                  }}
                >
                  {cnaid.id}
                </span>
              </td>
              <td colspan='2'><span style={{fontWeight: 'bold', font: 'normal normal bold 18px/21px proxima-nova', }} dangerouslySetInnerHTML={{__html: cnaid.title}}></span></td>
              <td colspan='4' dangerouslySetInnerHTML={{__html: cnaid.description}} ></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default FindingAid;
