import React from 'react';
import * as style from './purpose.module.scss';

const Purpose = () => {
  return (
    <section className={style.wrapper}>
      <div className={style.container}>
        <div className={style.purpose}>
          <h3>Inform</h3>
          <p>
          Search our online collections of sermon notes, papers, photographs, videos, 
          and audio recordings to guide you in your ministry calling.
          </p>
          <a href="/collections-guide" className="button">
            Explore Online
          </a>
        </div>
        <div className={style.purpose}>
          <h3>Inspire</h3>
          <p>
          Read about featured archive items and historical perspectives to honor the past and ignite the future.
          </p>
          <a
            href="https://billygrahamlibrary.org/category/the-archive-collection"
            className="button"
          >
            Find out more
          </a>
        </div>
        <div className={style.purpose}>
          <h3>Equip</h3>
          <p>
            Study principles of effective evangelism and apply it to your circle
            of influence.
          </p>
          <a
            href="https://billygraham.org/tv-and-radio/radio/audio-archives/"
            className="button"
          >
            Discover
          </a>
        </div>
      </div>
    </section>
  );
};

export default Purpose;
