import { useStaticQuery, graphql } from 'gatsby';

export const useQuery = () => {
  const props = useStaticQuery(graphql`
    query MyQuery {
      site: site {
        siteMetadata {
          siteUrl
        }
      }
      blogs: allMdx(
        filter: { frontmatter: { category: { eq: "blog" } } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        nodes {
          frontmatter {
            category
            date(formatString: "MMMM D, YYYY")
            excerpt
            featureImage
            featureImageAltText
            kaltura
            slug
            tag
            title
          }
          body
        }
      }
      faqs: allMdx(
        filter: { frontmatter: { category: { eq: "faq" } } }
        sort: { fields: frontmatter___order }
      ) {
        nodes {
          frontmatter {
            question
            answer
          }
          body
        }
      }
      cnaids: allMdx(
        filter: { frontmatter: { category: { eq: "finding_aid" } } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          frontmatter {
            order
            cntitle
          }
          body
        }
      }
    }
  `);
  return props;
};
